<template>
  <div>
    <!-- 两句诗 -->
    <div class="my-animation-slide-top">
      <twoPoem :isHitokoto="false"></twoPoem>
    </div>

    <div
      style="background: var(--background)"
      class="my-animation-slide-bottom"
    >
      <div class="about-wrap">
        <h1 style="font-size: 40px; font-weight: 500; letter-spacing: 5px">
          {{ text }}
        </h1>
      </div>
      <!-- 页脚 -->
      <myFooter></myFooter>
    </div>
  </div>
</template>

<script>
const twoPoem = () => import("./common/twoPoem");
const myFooter = () => import("./common/myFooter");

export default {
  components: {
    twoPoem,
    myFooter,
  },
  data() {
    return {
      sayShow: false,
      paramObj: {},
      text: "未返回数据",
      sayContent: [
        {
          talk: ["Hi, there👋", "这是一个 Vue2 Vue3 与 SpringBoot 结合的产物~"],
          reply: ["然后呢？ 😃", "少废话！ 🙄"],
        },
        {
          talk: [
            "😘",
            "本站平时仅用于交流和学习新知识",
            "如涉及侵权请联系站长删除对应资源，谢谢！！！",
          ],
          reply: ["这个网站有什么用吗？ 😂"],
        },
        {
          talk: [
            "拥有自己的独立网站难道不酷吗🚀",
            "那就摸鱼吧👋",
            "摸鱼大军请在聊天室集合🥝",
          ],
          reply: [],
        },
      ],
      sayIndex: 0,
    };
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {
    this.getParam();
    console.log(this.paramObj);
  },

  methods: {
    getParam() {
      // window.location.search
      var url = decodeURIComponent(location.href);
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(url.indexOf("?") + 1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      this.paramObj = theRequest;
      this.paramObj.state = this.paramObj.state.substring(
        0,
        this.paramObj.state.indexOf("#")
      );
      this.getLogin();
    },
    getLogin() {
      this.$http
        .get(this.$constant.baseURL + "/user/authorizationWx", {
          state: this.paramObj.state,
          code: this.paramObj.code,
        })
        .then((res) => {
          this.text = res.message;
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.about-wrap {
  text-align: center;
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px 80px;
  min-height: calc(100vh - 400px);
}

.about-box {
  min-height: 450px;
  padding: 5px;
  background-color: var(--maxMaxLightGray);
  border-radius: 10px;
}

.say-item-left {
  padding: 5px 12px;
  border-radius: 1rem;
  color: var(--maxGreyFont);
  background-color: var(--lightGray);
}

.say-item-right {
  padding: 5px 12px;
  border-radius: 1rem;
  color: var(--white);
  background-color: var(--translucent);
}

.say-left {
  display: flex;
  justify-content: left;
  margin: 15px;
}

.say-right {
  display: flex;
  justify-content: right;
  margin: 15px;
}

.say-select {
  cursor: pointer;
  background: var(--black);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 12px;
  margin-top: 20px;
  color: var(--white);
  border: 1px solid var(--black);
}

.say-select:hover {
  border: 1px solid var(--themeBackground);
  color: var(--themeBackground);
  box-shadow: 0 0 5px var(--themeBackground);
}
</style>
